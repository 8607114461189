/* .pace {
  pointer-events: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border: 0px;
  height: 1px;
  overflow: hidden;
  background:#1b1b1b;
}

.pace .pace-progress {
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
  max-width: 400px;
  position: fixed;
  z-index: 2000;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: grey;
}

.pace.pace-inactive {
  display: none;
}

#preloader{
  width:100%;
  height:100vh;
  background:#101010;
  overflow:hidden;
  position:fixed;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.p {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
} */



.title {

    position: absolute; 
    top: 42%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    color: #101010; 
    z-index: 99999; 
    opacity: 0; 
}

#preloader {
    margin: -30px;
    padding: 0;
    width: 100vw; 
    height: 100vh; 
    background: #000000; 
    position: fixed; 
    display: grid; 
    align-items: center; 
    justify-content: center;

}

.loading__text {
    letter-spacing: 30px; 
    color: #fff; 
    font-weight: 700; 
    position: relative; 
    top: -50px; 
    pointer-events: none;
    user-select: none;  
    text-align: center;
}

.pace {
    pointer-events: none;
    user-select: none;
    z-index: 2000; 
    position: fixed; 
    margin: auto; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    width: 400px; 
    border: 0; 
    height: 1px; 
    overflow: hidden; 
    background: #1b1b1b; 
}

.pace .pace-progress {
    
    max-width: 400px; 
    position: absolute; 
    z-index: 2000; 
    top: 0; 
    right: 100%; 
    height: 100%; 
    width: 100%; 
    background: #fff;
}